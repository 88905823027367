import React, { FC, useEffect, useRef, useState } from "react";
import { styled, Box, Stack } from "@mui/material";
import { renderGradientColor } from "../../../utils";
import FreeLogoBanner from "../../reusable/FreeLogoBanner";
import SignupEmailInput from "../dashboard/page-generator/preview/SignupInput";
import { Themes } from "../dashboard/page-generator/state";
import * as themes from "../dashboard/page-generator/customization/themes";
import { getButtonTheme } from "../dashboard/page-generator/themes/buttons";
import Banner from "../dashboard/page-generator/preview/Banner";
import SensitiveGate from "./SensitiveGate";
import ContactPage from "./ContactPage";
import PageLink from "../dashboard/page-generator/preview/links/BioLink";
import SocialIconsPanel from "../dashboard/page-generator/preview/SocialIconsPanel";
import TermsBanner from "../dashboard/page-generator/preview/TermsBanner";
import {
  AnalyticEventType,
  AppearanceModel,
  LinkType,
  PageButtonModel,
  PageModel,
  PageSettingsModel,
  QrModel,
  useAnalyticEventMutation,
} from "../../../generated/graphql";
import { SOCIAL_LINKS } from "../../../ui/Icon/socialIcons";
import { parseCookies } from "nookies";
import * as FILTERS from "../dashboard/page-generator/themes/filters";
import SignupInput from "../dashboard/page-generator/preview/SignupInput";
import { useRouter } from "next/router";
import PageHeader from "../dashboard/page-generator/preview/PageHeader";
import BioLinks from "../dashboard/page-generator/preview/links";
import PageBackground from "../dashboard/page-generator/preview/PageBackground";

interface Props extends AppearanceModel {
  id: string;
  links: PageModel["links"];
  settings: PageSettingsModel;
  qr: QrModel;
  pageName: string;
  qrUtm: string | undefined;
}

const StyledPageWr = styled(Box)<any>(
  ({ theme, font, button }) => `
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding:12px;
  display: flex;
  flex-direction: column;
  font-family: ${font};
  align-items: center;

  ${
    theme === "Custom"
      ? ""
      : themes[theme as keyof typeof themes]?.style || themes["Default"].style
  }

  ${
    button?.id &&
    `
    .button {
        ${getButtonTheme(button.id)};
    }`
  }
`
);

const Logo = styled(Box)`
  position: sticky;
  top: 15px;
`;

const cache: string[] = [];

const PageContent: FC<Props> = ({
  id,
  profileImage,
  title,
  theme,
  buttons,
  font,
  logo,
  links,
  social,
  settings,
  bio,
  qr,
  pageName,
  qrUtm,
}) => {
  const ref = useRef<any>(null);
  const { query } = useRouter();

  const openTime = useRef(Date.now());
  const [fireEvent, { loading }] = useAnalyticEventMutation();

  const [contactPage, setContactPage] = useState(false);
  const [sensitvePage, setSentivePage] = useState(!!settings.sensitive?.type);
  const [termsBanner, setTermsBanner] = useState(false);

  const { browserId } = parseCookies();

  const socialIcons =
    links.find(
      (l) => l.type === LinkType.SocialIcon && l.title === "Social Badges"
    )?.children[0].row || [];

  useEffect(() => {
    if (query.preview === "true") return;

    const ref = document.referrer.split(".").reverse()[1];

    fireEvent({
      variables: {
        data: {
          eventPayload: {
            name: AnalyticEventType.PageView,
            targetId: id,
            referer: ref,
            qrUtm,
          },
          browserId: browserId || "",
        },
      },
    });
  }, []);

  const fireLinkClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    //  e.preventDefault();
    const linkId = e.currentTarget.id;
    if (query.preview === "true") return;
    if (!cache.includes(linkId)) {
      cache.push(linkId);

      fireEvent({
        variables: {
          data: {
            eventPayload: {
              name: AnalyticEventType.PageLinkClick,
              targetId: linkId,
              clickTime: Math.round((Date.now() - openTime.current) / 1000),
              referer: document.referrer,
              qrUtm,
            },
            browserId: browserId || "",
          },
        },
      });
    }
  };

  useEffect(() => {
    let t: any = null;
    if (!sensitvePage) {
      links.forEach((link) => {
        if (link.leapTime !== -1) {
          t = setTimeout(() => {
            const l = link.url.includes("http") ? link.url : "//" + link.url;
            window.location.replace(l);
          }, link.leapTime * 1000);
        }
      });
    }
    return () => clearTimeout(t);
  }, [sensitvePage]);

  const _onAllowClick = () => {
    setSentivePage(false);
  };

  return sensitvePage ? (
    <SensitiveGate
      sensitive={settings.sensitive}
      onAllowClick={_onAllowClick}
    />
  ) : (
    <>
      <PageBackground
        theme={theme.id === "Custom" ? theme : undefined}
        isPreview={false}
      />
      <StyledPageWr
        font={font.family}
        theme={theme.id as Themes}
        button={buttons}
      >
        <Stack
          maxWidth="570px"
          width="100%"
          sx={{
            minHeight: "calc(100vh - 24px)",
            position: "relative",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {contactPage ? (
            <ContactPage
              profileImage={profileImage}
              onCloseClick={() => setContactPage(false)}
              qr={qr as any}
              pageName={pageName}
              contactColor={social?.iconsColor || "#000000"}
              closeColor={buttons?.custom?.color.textColor || "white"}
              contact={settings?.contact ?? {}}
            >
              {socialIcons
                .filter((l) => l.url.length > 0 && l.id && l.title.length > 0)
                .map((link, key) => (
                  <PageLink
                    key={key}
                    fireEvent={fireLinkClickEvent}
                    isContactLink={true}
                    link={{
                      id: link.id,
                      url: link.url,
                      thumbnail: `customIcon:${link.title}:${social?.iconsColor}`,
                      title: link.title,
                    }}
                    font={font}
                    button={buttons}
                    iconColor={buttons?.custom?.color.textColor || ""}
                  />
                ))}
            </ContactPage>
          ) : (
            <>
              {termsBanner && (
                <TermsBanner
                  termsBanner={settings.emailSignup?.terms}
                  closeBanner={() => setTermsBanner(false)}
                />
              )}
              <PageHeader bio={bio} profileImage={profileImage} title={title} />
              <Box
                sx={{
                  width: "100%",
                  flex: "1",
                }}
              >
                <BioLinks
                  onClick={fireLinkClickEvent}
                  pageId={id}
                  links={links}
                  font={font}
                  button={buttons}
                  settings={settings}
                  setContactPage={() => setContactPage(true)}
                  iconsColor={social?.iconsColor || "#000000"}
                />
              </Box>

              {logo && (
                <Logo>
                  <FreeLogoBanner />
                </Logo>
              )}
              <Banner preview={false} infoBanner={settings.infoBanner} />
            </>
          )}
        </Stack>
      </StyledPageWr>
    </>
  );
};

export default PageContent;
