import { GetServerSideProps, NextPage } from "next";
import { createApolloClient } from "../../../config/apollo/index";
import React, { FC } from "react";
import { gql } from "@apollo/client";
import { PageModel } from "../../../generated/graphql";
import PageContent from "../../../components/page/customer-page";
import Head from "next/head";
import { createFontsUrl } from "../../../utils";
import { parseCookies, setCookie } from "nookies";
import { v4 as uuidv4 } from "uuid";
import PublicLayout from "../../../components/main/PublicLayout";
import BioPageLayout from "../../../components/main/BioPageLayout";

interface Props {
  page: PageModel;
  pageName: string;
  qrUtm: string | undefined;
}

const GoogleAnalytics = (id: string) => {
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${id}');
          `,
        }}
      />
    </>
  );
};

const FbPixel = (id: string) => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${id});
                fbq('track', 'PageView');
              `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  );
};

const Index = ({ page, pageName, qrUtm }: Props) => {
  const { appearance, settings, qr, links } = page;

  const { browserId } = parseCookies();

  if (!browserId) {
    setCookie(null, "browserId", uuidv4(), { path: "/" });
  }

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href={`https://fonts.googleapis.com/css2?${createFontsUrl(
            appearance.font
          )}&display=swap`}
          rel="stylesheet"
        />
        <title>{settings?.seo?.title || ""}</title>

        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href={settings.favicon || "/favicon/favicon-32x32.png"}
        />

        <meta property="og:title" content={settings?.seo?.title || ""} />
        <meta name="twitter:title" content={settings?.seo?.title || ""} />
        <meta name="description" content={settings?.seo?.description || ""} />
        <meta property="og:image" content={appearance.profileImage || ""} />
        <meta name="twitter:image" content={appearance.profileImage || ""} />
        {settings?.analytics?.fb && FbPixel(settings.analytics.fb)}
        {settings?.analytics?.google &&
          GoogleAnalytics(settings.analytics.google)}
      </Head>
      <PageContent
        id={page.id}
        qrUtm={qrUtm}
        pageName={pageName}
        {...appearance}
        links={links || []}
        settings={settings}
        qr={qr}
      />
    </>
  );
};

Index.getLayout = function getLayout(page: any) {
  return <BioPageLayout>{page}</BioPageLayout>;
};

export const getServerSideProps: GetServerSideProps = async (req) => {
  const {
    query: { slug, qr },
  } = req;

  if (slug === "nbooth02") {
    return {
      redirect: {
        destination: "https://joyqr.com/nikolebooth",
        permanent: false,
      },
    };
  }

  const client = createApolloClient();
  try {
    const { data, error } = await client.query({
      variables: {
        pagename: slug,
      },
      query: gql`
        query ($pagename: String!) {
          page(pagename: $pagename) {
            id
            links {
              id
              title
              embeded
              embededIframe
              url
              priority
              thumbnail
              isActive
              leapTime
              type
              children {
                row {
                  id
                  title
                  url
                  thumbnail
                  isActive
                  type
                  embeded
                  priority
                  embededIframe
                  isLinkValid @client
                  childRow
                }
              }
              isLinkValid @client
            }
            appearance {
              profileImage
              title {
                value
                fontSize
                font
                color
              }
              social {
                iconsColor
                buttonIconsColor
                icons {
                  id
                  url
                }
              }
              bio {
                value
                font
                fontSize
                color
              }
              theme {
                id
                custom {
                  bgImage
                  bgFilter
                  color {
                    firstColor
                    secondColor
                    type
                  }
                }
              }
              buttons {
                id
                custom {
                  color {
                    bgColor
                    shadowColor
                    textColor
                    hoverColor
                  }
                  stroke {
                    color
                    width
                  }
                }
              }
              font {
                id
                size
                family
              }
              logo
            }
            settings {
              favicon
              analytics {
                fb
                google
                utmCodes {
                  utmMedium
                  utmSource
                }
              }
              contact {
                email
                phone
              }
              emailSignup {
                buttonText
                position
                successMessage
                colors {
                  buttonColor
                  buttonTextColor
                }
                terms {
                  linkLabel
                  termsLabel
                  termsLink
                }
              }
              smsSignup {
                buttonText
                position
                successMessage
                colors {
                  buttonColor
                  buttonTextColor
                }
                terms {
                  linkLabel
                  termsLabel
                  termsLink
                }
              }
              infoBanner {
                title
                font
                content
                buttonLabel
                buttonUrl
                colors {
                  buttonBg
                  background
                  buttonLabel
                  content
                  title
                }
              }
              sensitive {
                type
              }
              seo {
                title
                description
              }
            }
            qr {
              tags
              padding
              module {
                id
                color {
                  firstColor
                  secondColor
                  type
                  angle
                }
              }
              eyes {
                lB {
                  external
                  externalId
                  internalId
                  internal
                }
                lT {
                  externalId
                  internalId
                  external
                  internal
                }
                rT {
                  externalId
                  internalId
                  external
                  internal
                }
              }
              shape {
                type
                bgColor {
                  firstColor
                  secondColor
                  type
                  angle
                }
                bgSize
                strokeColor {
                  firstColor
                  secondColor
                  type
                  angle
                }
                stroke
              }
              logo: _logo {
                hideBg
                margin
                size
                thumbnail
              }
              joyLogo
            }
          }
        }
      `,
    });

    return {
      props: {
        qrUtm: data.page.qr.tags.includes(qr) ? qr || null : null,
        page: data.page,
        pageName: slug,
      },
    };
  } catch (error: any) {
    if (error["graphQLErrors"][0].message === "NotVerified") {
      return {
        redirect: {
          destination: "https://joyqr.com/verified",
          permanent: false,
        },
      };
    }

    return {
      redirect: {
        destination: "https://joyqr.com/404",
        permanent: false,
      },
    };
  }
};

export default Index;
