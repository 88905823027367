import Link from "next/link";
import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import {
  EmailSignup,
  PageSettingsModel,
  Terms,
} from "../../../../../generated/graphql";

interface Props {
  termsBanner: EmailSignup["terms"];
  closeBanner: () => void;
}

const StyledBanner = styled.div`
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  border-radius: 0 0 24px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

const TermsBanner: FC<Props> = ({ termsBanner, closeBanner }) => {
  const _closeBanner = () => {
    closeBanner && closeBanner();
  };

  /* return termsBanner ? (
    <StyledBanner>
      <Text
        value={termsBanner.termsLabel + " " + ".*."}
        size="16"
        textAlign="center"
        p={15}
        marginBottom="0.5rem"
        innerElements={[
          <Link href={termsBanner.termsLink} passHref>
            <a target="_blank">{termsBanner.linkLabel}</a>
          </Link>,
        ]}
      />
      <span
        onClick={_closeBanner}
        style={{
          cursor: "pointer",
          alignSelf: "flex-end",
          marginRight: "25px",
          marginTop: "5px",
          paddingBottom: "5px",
        }}>
        ✕
      </span>
    </StyledBanner>
  ) : null;*/

  return <div></div>;
};

export default TermsBanner;
