import { Button, Typography } from "@mui/material";
import Router from "next/router";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { PageSettingsInput } from "../../../generated/graphql";

interface Props {
  sensitive: PageSettingsInput["sensitive"];
  onAllowClick: () => void;
  isPreview?: boolean;
}

const StyledContent = styled.div<{ isPreview: boolean }>`
  padding: 16px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  ${({ isPreview }) => (isPreview ? "height:100%;" : "min-height:100vh;")}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    151deg,
    rgba(25, 67, 247, 1) 6%,
    rgba(31, 171, 53, 1) 86%
  );
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  width: 100%;
  align-items: center;

  & > * {
    margin-bottom: 1rem;
  }
`;

const SensitiveGate: FC<Props> = ({
  sensitive,
  onAllowClick,
  isPreview = false,
}) => {
  if (!sensitive) return null;
  const yesText =
    sensitive.type === "sensitive"
      ? "Show Content"
      : `I'm over ${sensitive.type}+`;

  const noText =
    sensitive.type === "sensitive" ? "Go Back" : `I'm under ${sensitive.type}+`;

  return (
    <StyledContent isPreview={isPreview}>
      <Content>
        <Typography
          sx={{
            color: "#ffffff",
            fontSize: "32px",
            textAlign: "center",
          }}
        >
          Sensitive Content
        </Typography>

        <Typography
          sx={{
            color: "#fff",
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          You must be a legal age to view this content
        </Typography>

        <Button
          onClick={() => onAllowClick()}
          fullWidth={true}
          sx={{
            mt: 5,
            backgroundColor: "#ffffff",
            p: 2,
            font: "Poppins",
            fontWeight: 600,
            color: "#0F75FB",
            "&:hover": {
              backgroundColor: "#66B045",
              color: "white",
            },
          }}
        >
          {yesText}
        </Button>
        <Button
          variant="link"
          fullWidth={true}
          onClick={() => Router.push("https://joyqr.com/terms")}
          sx={{
            mt: 5,
            backgroundColor: "#0F75FB",
            p: 2,
            font: "Poppins",
            fontWeight: 600,
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#66B045",
              color: "white",
            },
          }}
        >
          {noText}
        </Button>
      </Content>
    </StyledContent>
  );
};

export default SensitiveGate;
