import { Container, GlobalStyles } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

const BioPageLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <GlobalStyles
        styles={(props: any) => ({
          "*": {
            boxSizing: "border-box",
            padding: 0,
            margin: 0,
          },
          body: {
            textRendering: "optimizeLegibility",
            " -webkit-font-smoothing": "antialiased",
            overflowX: "hidden",
          },
        })}
      />
      {children}
    </>
  );
};

export default BioPageLayout;
