import { Box, BoxProps } from "@mui/material";
import { useRouter } from "next/router";
import React, { FC, PropsWithChildren } from "react";
import { SubscriptionPlan } from "../../../generated/graphql";
import { useModal } from "../../../ui/Modal";
import SignUp from "../../page/dashboard/auth/SignUp";
import useSubscriptionPlan from "../../page/dashboard/common/useSubscriptionPlan";
import Modal from "../modals/Modal";
import PaywallModal from "../modals/PaywallModal";

interface Props extends Omit<BoxProps, "id" | "onClick"> {
  isSelected: boolean;
  id?: any;
  minPlan: SubscriptionPlan;
  onClick: (id: number | string) => void;
}

export const borderInactive = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%2300000033' stroke-width='8' stroke-dasharray='16%2c16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`;
export const borderActive = ` url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%2304080FFF' stroke-width='8' stroke-dasharray='16%2c16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`;
export const borderSelected = ` url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%230F75FBFF' stroke-width='8' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`;

const SelectableItem: FC<PropsWithChildren<Props>> = ({
  id,
  children,
  isSelected,
  onClick,
  minPlan,
  sx,
  ...props
}) => {
  const { renderModal, closeModal, openModal } = useModal();
  const { push } = useRouter();
  const { checkPermision, isLogged } = useSubscriptionPlan();
  const isAvaulableForUserPlan = checkPermision(minPlan);

  const _onClick = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    if ((id !== undefined && isAvaulableForUserPlan) || !isLogged) {
      onClick && onClick(id);
    } else {
      if (isLogged) {
        openModal(<PaywallModal minPlan={minPlan} onClose={closeModal} />);
      }
    }
  };

  return (
    <>
      {renderModal()}
      <Box
        sx={{
          width: "100%",
          cursor: "pointer",
          display: "flex",
          padding: "7px",
          borderRadius: 2,
          "&:hover": {
            outline: "2px solid #0F75FB",
          },
          outline: isSelected ? "2px solid #0F75FB" : "none",
          ...sx,
        }}
        {...props}
        onClick={_onClick}
      >
        {children}
      </Box>
    </>
  );
};

export default SelectableItem;
